/* Aktiv Grotesk - NIQ*/
@font-face {
  font-family: 'Aktiv Grotesk';
  src: url('../src/assets/fonts/AktivGrotesk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

#root {
  min-width: 375px;
}

#main {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins'
}

@media screen and (max-width: 768px) {
  #main {
    /* Your styles here */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* padding: 20px; */
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Aktiv Grotesk', sans-serif; */
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}